<template>
  <div>
    <div class="flex items-center mb-4 gap-x-2.5">
      <el-select v-model="warehouse_id" size="small">
        <el-option
          v-for="w in warehouses"
          :key="w.id"
          :value="w.id"
          :label="w.name"
        ></el-option>
      </el-select>
      <el-select v-model="express" size="small">
        <el-option :value="0" label="Заммлер"></el-option>
        <el-option :value="1" label="Только express"></el-option>
        <el-option :value="2" label="Своя доставка"></el-option>
        <el-option :value="3" label="Самовывоз"></el-option>
      </el-select>
      <div>
        <el-checkbox v-model="isNotPrinted">Только новые</el-checkbox>
      </div>
      <el-button
        @click="loadOrders"
        class="ml-5"
        :disabled="loading"
        :icon="loading ? 'el-icon-loading' : ''"
        size="small"
        type="primary"
        >Загрузить заказы</el-button
      >
    </div>

    <div>Выбрано: {{ total }}</div>
    <div class="flex items-start">
      <el-input type="textarea" v-model="orderIds" class="mr-2"></el-input>
      <el-button
        @click="getProducts"
        round
        size="small"
        type="primary"
        plain
        :disabled="loading"
        :icon="loading ? 'el-icon-loading' : ''"
        >Загрузить товары</el-button
      >
    </div>

    <hr class="my-3" />

    <template v-if="orders.length">
      <div class="flex gap-x-2.5 items-center">
        <el-button
          @click="generateWaybills"
          :disabled="loading"
          :icon="loading ? 'el-icon-loading' : ''"
          size="small"
          type="primary"
          round
          plain
          >Скачать накладные</el-button
        >
        <div>Заказы с накладными: {{ ordersWithWaybill.length }}</div>
      </div>

      <div v-for="o in orders" :key="o.code" class="mt-5">
        <div
          class="text-xs font-medium mb-1.5 flex justify-between items-center"
        >
          <div>{{ o.code }} | {{ o.total }} | {{ o.waybill }}</div>
          <el-button
            @click="() => saveImei(o)"
            size="mini"
            round
            :disabled="loading"
            :icon="loading ? 'el-icon-loading' : ''"
            >Save</el-button
          >
        </div>
        <el-table :data="o.products" size="mini" border>
          <el-table-column label="Название">
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column label="Количество" align="right">
            <template slot-scope="scope">{{ scope.row.quantity }}</template>
          </el-table-column>
          <el-table-column label="Цена" align="right">
            <template slot-scope="scope">{{ scope.row.basePrice }}</template>
          </el-table-column>
          <el-table-column label="Гарантия" align="right">
            <template slot-scope="scope">
              <el-input v-model="scope.row.guarantee" size="mini"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="IMEI">
            <template slot-scope="scope">
              <el-input v-model="scope.row.imei" size="mini"></el-input>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
  </div>
</template>

<script>
import apiClient from "@/api/apiClient";

export default {
  name: "Waybills",
  data() {
    return {
      warehouse_id: 1,
      express: 0,
      isNotPrinted: true,

      waybill_orders: [],
      orderIds: "",
      orders: [],

      loading: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    total() {
      return this.orderIds.split("\n").filter((i) => !!i).length;
    },
    ordersWithWaybill() {
      return this.orders.filter((o) => o.waybill);
    },
    accountingIdsForPrint() {
      if (this.express === 0) {
        return this.ordersWithWaybill.map((o) => ({
          id: o.id,
          waybill: o.waybill,
        }));
      }

      return this.orders.map((o) => ({
        id: o.id,
        waybill: o.waybill,
      }));
    },
    warehouses() {
      if (this.user && this.user.role === "shymkent") {
        return [{ id: 3, name: "Шымкент" }];
      }

      return [
        { id: 1, name: "Алматы" },
        { id: 2, name: "Астана" },
        { id: 3, name: "Шымкент" },
      ];
    },
  },
  mounted() {
    console.log(this.user);
    if (this.user && this.user.role === "shymkent") {
      this.warehouse_id = 3;
    }
  },
  methods: {
    async loadOrders() {
      this.loading = true;
      try {
        const { data } = await apiClient.get(`/api/kaspi/orders`, {
          params: {
            warehouse_id: this.warehouse_id,
            express: this.express,
            is_not_printed: this.isNotPrinted,
          },
        });

        this.waybill_orders = data.order_ids;
        this.orderIds = data.order_ids.map((i) => i.code).join("\n");
      } catch (e) {
        console.log(e.message);
        this.$message.error(e.message);
      }
      this.loading = false;
    },
    async getProducts() {
      if (!this.orderIds.trim()) {
        return;
      }

      this.loading = true;
      const { data } = await apiClient.get("/api/accounting/by_basis", {
        params: {
          basis: this.orderIds.split("\n").join(","),
          waybill: this.waybill_orders.length > 0 ? 0 : 1,
        },
      });

      console.log(data);
      this.loading = false;
      this.orders = data.orders.map((i) => {
        const o = this.waybill_orders.find((j) => j.code === i.code);
        const waybill = i.waybill || (o && o.waybill) || null;
        return { ...i, waybill };
      });
    },
    async generateWaybills() {
      this.loading = true;
      const accounting_ids = this.accountingIdsForPrint;

      const { data } = await apiClient.post("/api/accounting/0/waybill", {
        accounting_ids: accounting_ids,
      });

      this.loading = false;
      if (!data.success) return;
      window.open(`https://api.alasal.kz/waybills/${data.waybill}`);
      this.$confirm(
        `Заказы с готовыми накладными: ${this.accountingIdsForPrint.length}
                <br/>Ошибок: ${data.errors.length}
                <br/>Страниц в pdf должно быть: ${
                  this.accountingIdsForPrint.length - data.errors.length
                }`,
        "Подтвердите действие",
        {
          confirmButtonText: "Да, все верно",
          cancelButtonText: "Отмена",
          type: "warning",
          dangerouslyUseHTMLString: true,
        }
      ).then(() => {
        const errors = data.errors.map((e) => e.accounting_id);
        this.markAsPrinted(
          accounting_ids.map((o) => o.id).filter((i) => !errors.includes(i))
        );
      });
      console.log(data);
    },
    async saveImei(order) {
      this.loading = true;
      const { data } = await apiClient.post("/api/accounting/by_basis", {
        products: order.products.map((p) => ({
          imei: p.imei,
          storage_id: p.storage_id,
          guarantee: p.guarantee,
          product_id: p.product_id,
        })),
      });
      this.$notify.success("Успех");
      console.log(data);
      this.loading = false;
    },
    async markAsPrinted(accountingIds) {
      const { data } = await apiClient.post("/api/accounting/by_basis", {
        action: "print_flag",
        accounting_ids: accountingIds,
      });
      this.$notify.success("Успех");
      console.log(data);
    },
  },
};
</script>

<style scoped></style>
