<script>
import { getPreorders } from "@/api/preorder";
import {
  createProductPreOrder,
  getOfferDetails,
  removeProductPreOrder,
  saveKaspiOnsaleProducts,
} from "@/api/product";
import SearchCatalogProducts from "@/components/SearchCatalogProducts.vue";
import { getCities } from "@/api/branch";
import { format } from "date-fns";

export default {
  name: "PreorderProducts",
  components: { SearchCatalogProducts },
  dampingOptions: {
    low: "Low",
    mid: "Middle",
    high: "High",
    super_high: "Super high",
  },
  data() {
    return {
      loading: false,
      cities: [],
      preorders: [],
      form: {
        product_id: null,
        city_id: null,
        pre_order: 0,
      },

      product: null,

      filters: { onsale: "no" },
      offerDetails: null,
    };
  },
  computed: {
    filteredPreOrders() {
      return this.preorders;
    },
  },
  mounted() {
    this.getPreorders();

    getCities().then((res) => {
      this.cities = res;
      console.log(res);
    });
  },
  methods: {
    getPreorders() {
      getPreorders(this.filters.onsale).then((preorders) => {
        this.preorders = preorders;
      });
    },
    async removePreOrder({ product_id, city_id }) {
      await removeProductPreOrder({ product_id, city_id });
      this.getPreorders();
    },
    selectProduct(product) {
      this.product = product;
      this.form.product_id = product.id;
    },
    async addPreOrder() {
      if (!this.form.product_id || !this.form.city_id) return;

      this.loading = true;
      const res = await createProductPreOrder({
        product_id: this.form.product_id,
        city_id: this.form.city_id,
        pre_order: this.form.pre_order || 0,
      });

      this.loading = false;

      if (res.success) {
        await this.getPreorders();
      } else {
        this.$message.error(`Ошибка: Уже есть в базе такой предзаказ`);
      }
    },
    async save({ kp_id, min_price, mode, price, step, city_id }) {
      try {
        await saveKaspiOnsaleProducts({
          kp_id,
          min_price,
          mode,
          price,
          step,
          city_id,
        });
        this.$message.success("Success");
      } catch (e) {
        this.$message.error("Error", e.message);
      }
    },
    async getOffers(kaspiSku, cityId) {
      const res = await getOfferDetails(kaspiSku, cityId);
      if (res.offer) {
        this.offerDetails = {
          offers: res.offer.offers,
          damped_at: res.damped_at,
        };
      }
    },
    dateFormat(timestamp) {
      return format(timestamp + 5 * 60 * 60 * 1000, "dd MMM, yyyy HH:mm");
    },
    close() {
      this.offerDetails = null;
    },
  },
};
</script>

<template>
  <div>
    <h1 class="font-semibold text-xl mb-2 flex justify-between items-center">
      <div class="mb-3">Предзаказы ({{ filteredPreOrders.length }})</div>
      <el-select v-model="filters.onsale" @change="getPreorders">
        <el-option label="Только предзаказы" value="no"></el-option>
        <el-option label="Предзаказы с наличием" value="yes"></el-option>
      </el-select>
    </h1>

    <div
      class="my-5 p-5 rounded border border-solid border-gray-300 bg-gray-100"
    >
      <SearchCatalogProducts @select:product="selectProduct" class="mb-2" />
      <div v-if="product" class="mb-2">
        Выбранный продукт: {{ product.name }}
      </div>
      <div class="flex">
        <el-select v-model="form.city_id" placeholder="Город" size="medium">
          <el-option
            v-for="c in cities"
            :key="c.id"
            :label="c.name"
            :value="c.id"
          ></el-option>
        </el-select>
        <el-input-number
          v-model="form.pre_order"
          type="number"
          placeholder="Предзаказ"
          size="medium"
          class="mr-2 ml-2"
        ></el-input-number>
        <el-button
          @click="addPreOrder"
          :disabled="loading"
          icon="el-icon-plus"
          plain
          round
          type="primary"
          size="medium"
        >
          Добавить
        </el-button>
      </div>
    </div>

    <el-table :data="filteredPreOrders" border size="small">
      <el-table-column align="center" type="index"></el-table-column>
      <el-table-column prop="product_name" label="Название продукта">
        <template slot-scope="scope">
          <router-link
            class="text-blue-600"
            target="_blank"
            :to="{
              name: 'Product',
              params: { id: scope.row.product_id },
            }"
          >
            [{{ scope.row.product_id }}]. {{ scope.row.product_name }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="city_name" label="Название города">
        <template slot-scope="{ row }">
          {{ row.city_name }} <br />
          <el-button
            v-if="row.mode"
            size="small"
            @click="getOffers(row.kaspi_sku, row.city_id)"
            >Офферы</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="pre_order"
        label="Предзаказ"
        align="right"
      ></el-table-column>
      <el-table-column prop="sku" label="SKU" width="150">
        <template slot-scope="{ row }">
          <a
            v-if="row.kaspi_sku"
            class="text-blue-600"
            target="_blank"
            :href="'https://kaspi.kz/shop/p/p-' + row.kaspi_sku + '/'"
          >
            {{ row.sku }} <br />
            {{ row.kaspi_sku }}
          </a>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="Цена">
        <template slot-scope="{ row }">
          <el-input v-model.number="row.price" size="mini"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="damping" label="Демпинг" align="center">
        <template slot-scope="{ row }">
          <el-select v-model="row.mode" clearable size="mini">
            <el-option
              v-for="(value, key) in $options.dampingOptions"
              :key="key"
              :value="key"
              :label="value"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="min_price" label="Мин цена">
        <template slot-scope="{ row }">
          <el-input v-model.number="row.min_price" size="mini"></el-input>
        </template>
      </el-table-column>
      <!--      <el-table-column prop="step" label="Шаг">-->
      <!--        <template slot-scope="{ row }">-->
      <!--          <el-input v-model.number="row.step" size="mini"></el-input>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column prop="step" label="Действия" align="center">
        <template slot-scope="{ row }">
          <el-button @click="() => save(row)" size="mini" type="primary" plain
            >Сохранить</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="Действия" align="right">
        <template slot-scope="scope">
          <el-button
            @click="() => removePreOrder(scope.row)"
            size="small"
            round
            plain
            type="danger"
            icon="el-icon-delete"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-drawer v-if="!!offerDetails" :visible="true" :before-close="close">
      <div class="px-5">
        <div class="text-right mb-3">
          {{ dateFormat(offerDetails.damped_at * 1000) }}
        </div>
        <el-table
          v-if="offerDetails.offers"
          border
          size="small"
          :data="offerDetails.offers"
        >
          <el-table-column
            type="index"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column prop="merchantName" label="Магазин">
          </el-table-column>
          <el-table-column prop="price" label="Цена" align="right">
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </div>
</template>

<style scoped></style>
