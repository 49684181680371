<template>
  <div class="home" style="margin: -30px; height: calc(100vh - 60px)">
    <div class="flex flex-col h-full">
      <div
        class="flex items-center justify-between gap-x-2.5 flex-shrink-0 border-b bg-gray-50 px-5 py-2"
      >
        <div class="flex items-center">
          <el-date-picker
            v-model="filterDate"
            @change="handleFiltersChange"
            type="daterange"
            size="small"
            range-separator=" - "
            start-placeholder="Дата с"
            end-placeholder="Дата по"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
          <div class="flex ml-3">
            <el-input
              v-model="filters.query"
              size="small"
              placeholder="Поиск"
              class="mr-1.5"
              clearable
              @keyup.enter.native="handleFiltersChange"
              @clear="handleFiltersChange"
            ></el-input>
            <el-button @click="handleFiltersChange" size="small"
              >Поиск</el-button
            >
          </div>
        </div>
        <el-upload
          :action="uploadUri"
          :headers="headers"
          :show-file-list="false"
          :on-success="handleUploadSuccess"
          :before-upload="handleBeforeUpload"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          :data="{ type: 'file' }"
        >
          <el-button
            size="small"
            :disabled="loading"
            ref="upload"
            plain
            type="warning"
          >
            Загрузить данные kaspi
          </el-button>
        </el-upload>
      </div>
      <div class="w-full h-full overflow-y-auto flex justify-center">
        <ul class="states">
          <li v-for="(state, key) in states" :key="key" class="state-column">
            <div class="state-header">
              {{ state.title }} | {{ state.total }}
            </div>
            <div class="reviews">
              <router-link
                v-for="review in state.reviews"
                :key="review.order_code"
                :to="{
                  name: 'reviews_item',
                  params: { review_id: review.order_code },
                }"
                class="review"
              >
                <div class="flex items-center justify-between">
                  <div>
                    <span v-if="review.rating" class="text-yellow-600 mr-2">
                      {{ review.rating }} <i class="el-icon-star-on"></i>
                    </span>
                    {{ review.order_code }}
                  </div>
                  <div class="text-gray-500">
                    <template v-if="review.rating_status === 'action'">
                      {{
                        review.rating_date &&
                        dateTimeFormat(review.rating_date + 60 * 60)
                      }}
                    </template>
                    <template v-else>
                      {{ dateTimeFormat(review.updated_at) }}
                    </template>
                  </div>
                </div>
                <div class="mt-2">
                  {{ review.first_name }} {{ review.last_name }} <br />
                  +7{{ review.phone }}
                </div>
                <ul class="products">
                  <li v-for="(p, index) in review.products" :key="p.id">
                    {{ index + 1 }}. {{ p.name }}
                  </li>
                </ul>
              </router-link>
              <el-button
                v-if="state.total > filters.limit * state.page"
                @click="() => loadMore(key)"
                size="mini"
                type="primary"
                plain
                >загрузить еще</el-button
              >
            </div>
          </li>
        </ul>
      </div>
    </div>

    <router-view @fetch="getReviews" @move="moveReview"></router-view>
  </div>
</template>
<script>
import { importReviews } from "@/api/accounting";
import { dateTimeFormat } from "@/helpers/formatter";

import LocalStorageService from "@/services/LocalStorageService";
import { getReviews } from "@/api/reviews";
const localStorageService = LocalStorageService.getService();

export default {
  name: "Home",
  components: {},
  data: () => ({
    data: [],
    loading: false,

    uploadLoading: false,
    uploadUri: `${process.env.VUE_APP_DOMAIN}/api/upload`,

    filters: {
      query: "",
      start_date: null,
      stop_date: null,
      limit: 20,
    },

    states: {
      new: {
        title: "Новые",
        total: 0,
        page: 1,
        reviews: [],
      },
      interest: { title: "Whatsapp", total: 0, page: 1, reviews: [] },
      decision: { title: "Отправлена ссылка", total: 0, page: 1, reviews: [] },
      action: { title: "Проставлен отзыв", total: 0, page: 1, reviews: [] },
      reject: { title: "Отказ", total: 0, page: 1, reviews: [] },
    },
  }),
  computed: {
    filterDate: {
      get() {
        if (this.filters.start_date && this.filters.stop_date) {
          return [this.filters.start_date, this.filters.stop_date];
        }
        return [];
      },
      set(val) {
        if (val) {
          this.filters.start_date = val[0];
          this.filters.stop_date = val[1];
        } else {
          this.filters.start_date = null;
          this.filters.stop_date = null;
        }
      },
    },
    headers() {
      const token = localStorageService.getAccessToken();
      return { Authorization: `Bearer ${token}` };
    },
  },
  async created() {
    this.parseUrlQuery();
    await this.getReviews();
  },
  methods: {
    async getReviews() {
      this.loading = true;
      for (const state of Object.keys(this.states)) {
        const result = await getReviews({
          ...this.filters,
          rating_status: state === "new" ? null : state,
        });
        const { data, total } = result;
        this.states[state].reviews = data;
        this.states[state].total = total;
      }

      this.loading = false;
    },
    async loadMore(state) {
      this.states[state].page += 1;
      const result = await getReviews({
        ...this.filters,
        rating_status: state === "new" ? null : state,
        page: this.states[state].page,
      });

      this.states[state].reviews = [
        ...this.states[state].reviews,
        ...result.data,
      ];
    },
    async handleFiltersChange(param) {
      if (param !== "page") {
        this.filters.page = 1;
      }
      this.changeUrlSearchParams();
      await this.getReviews();
    },
    dateTimeFormat(timestamp) {
      return dateTimeFormat(timestamp * 1000);
    },
    handleBeforeUpload() {
      this.uploadLoading = true;
    },
    async handleUploadSuccess(res) {
      try {
        this.uploadLoading = false;
        const result = await importReviews(res.url);

        if (result.success) {
          this.$message.success("Успешно");
          await this.getReviews();
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
    },
    moveReview(review) {
      const order = this.states[review.from].reviews.find(
        (r) => r.order_code === review.orderCode
      );

      if (!order) return;

      this.states[review.from].reviews = this.states[
        review.from
      ].reviews.filter((r) => r.order_code !== review.orderCode);

      this.states[review.to].reviews.unshift(order);
    },
    changeUrlSearchParams() {
      this.$router.replace({
        query: {
          start_date: this.filters.start_date,
          stop_date: this.filters.stop_date,
        },
      });
    },
    parseUrlQuery() {
      const { start_date, stop_date } = this.$route.query;
      this.filters.start_date = start_date;
      this.filters.stop_date = stop_date;
    },
  },
};
</script>

<style scoped lang="scss">
.states {
  @apply h-full overflow-y-auto flex gap-x-2.5 mx-5 pt-5;
  .state-column {
    @apply flex-shrink-0 w-60 h-full overflow-y-auto flex flex-col;
    .state-header {
      @apply text-center border-0 border-b border-gray-200 text-xs text-gray-500 py-2;
    }

    .reviews {
      @apply h-full overflow-y-auto flex flex-col gap-y-1.5 py-2;

      .review {
        font-size: 11px;
        @apply border border-solid border-gray-200 rounded bg-gray-50 p-2 leading-4;
        .products {
          @apply mt-2;
          li {
            & + li {
              @apply mt-1;
            }
          }
        }
      }
    }
  }
}
</style>
