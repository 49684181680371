<script>
import { getDampings } from "@/api/preorder";
import { createProductDamping, removeProductDamping } from "@/api/product";
import SearchCatalogProducts from "@/components/SearchCatalogProducts.vue";
import { getCities } from "@/api/branch";

export default {
  name: "DampingProducts",
  components: { SearchCatalogProducts },
  data() {
    return {
      drawer: false,
      loading: false,
      cities: [],
      dampings: [],

      form: {
        product_id: null,
        city_id: null,
        min_price: 0,
        step: 0,
        interval: "low",
      },

      product: null,
    };
  },
  watch: {
    drawer(val) {
      if (!val) {
        this.product = null;
      }
    },
  },
  mounted() {
    this.getDampings();

    getCities().then((res) => {
      this.cities = res;
      console.log(res);
    });
  },
  methods: {
    getDampings() {
      getDampings().then((dampings) => {
        this.dampings = dampings;
      });
    },
    async removeDamping({ product_id, city_id }) {
      console.log(city_id);
      await removeProductDamping({ product_id, city_id });
      this.getDampings();
    },
    selectProduct(product) {
      this.product = product;
      this.form.product_id = product.id;
    },
    async addDamping() {
      if (!this.form.product_id || !this.form.city_id || !this.form.min_price)
        return;

      this.loading = true;
      const res = await createProductDamping(this.form);

      this.loading = false;

      if (res.success) {
        this.$message.success(`Успешно`);
        this.drawer = false;
        await this.getDampings();
      } else {
        this.$message.error(`Ошибка: Уже есть в базе такой предзаказ`);
      }
    },
    editDamping(damping) {
      console.log(damping);
      this.drawer = true;
      this.form = {
        product_id: damping.product_id,
        city_id: damping.city_id,
        min_price: damping.min_price,
        step: damping.step,
        interval: damping.interval,
      };

      this.product = { name: damping.product_name };
    },
  },
};
</script>

<template>
  <div>
    <h1 class="font-semibold text-xl mb-5 flex justify-between items-center">
      <div class="mb-3">Демпинг</div>
      <el-button @click="drawer = true" size="small" round plain type="primary"
        >Создать</el-button
      >
    </h1>

    <el-table :data="dampings" border size="small">
      <el-table-column prop="product_name" label="Название продукта">
        <template slot-scope="scope">
          <router-link
            class="text-blue-600"
            target="_blank"
            :to="{
              name: 'Product',
              params: { id: scope.row.product_id },
            }"
          >
            [{{ scope.row.product_id }}]. {{ scope.row.product_name }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="city_name"
        label="Город"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="min_price"
        label="Мин цена"
        align="right"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="step"
        label="Шаг"
        align="right"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="interval"
        label="Интервал"
        align="right"
        width="100"
      ></el-table-column>
      <el-table-column label="Действия" align="right" width="130">
        <template slot-scope="scope">
          <el-button
            @click="() => editDamping(scope.row)"
            size="small"
            round
            plain
            type="primary"
            icon="el-icon-edit"
          ></el-button>

          <el-button
            @click="() => removeDamping(scope.row)"
            size="small"
            round
            plain
            type="danger"
            icon="el-icon-delete"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-drawer :visible="drawer" @close="drawer = false">
      <div class="px-5">
        <SearchCatalogProducts @select:product="selectProduct" class="mb-2" />
        <div v-if="product" class="mb-2">
          Выбранный продукт: {{ product.name }}
        </div>

        <div class="mb-2">
          <div>Город</div>
          <el-select
            v-model="form.city_id"
            placeholder="Город"
            size="medium"
            class="w-full"
          >
            <el-option
              v-for="c in cities"
              :key="c.id"
              :label="c.name"
              :value="c.id"
            ></el-option>
          </el-select>
        </div>

        <div class="mb-2">
          <div>Мин цена</div>
          <el-input-number
            v-model="form.min_price"
            type="number"
            placeholder="Мин цена"
            size="medium"
            class="w-full"
          ></el-input-number>
        </div>

        <div class="mb-2">
          <div>Шаг</div>
          <el-input-number
            v-model="form.step"
            type="number"
            placeholder="Шаг"
            size="medium"
            class="w-full"
          ></el-input-number>
        </div>
        <div>
          <div>Интервал</div>
          <el-select
            v-model="form.interval"
            placeholder="Interval"
            size="medium"
            class="w-full"
          >
            <el-option label="Low" value="low"></el-option>
            <el-option label="Mid" value="mid"></el-option>
            <el-option label="High" value="high"></el-option>
          </el-select>
        </div>
        <div class="text-right mt-5">
          <el-button
            @click="addDamping"
            :disabled="loading"
            icon="el-icon-plus"
            plain
            round
            type="primary"
            size="medium"
          >
            Добавить
          </el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<style scoped></style>
