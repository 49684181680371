import apiClient from "@/api/apiClient";

export const getPreorders = async (availability) => {
  const result = await apiClient.get("/api/preorders", {
    params: { availability },
  });
  return result.data.preorders;
};

export const getDampings = async () => {
  const result = await apiClient.get("/api/dampings");
  return result.data.dampings;
};
