import apiClient from "@/api/apiClient";

export const getReviews = async (filters) => {
  const result = await apiClient.get("/api/reviews", {
    params: filters,
  });
  return result.data;
};

export const getReview = async (orderCode) => {
  const result = await apiClient.get(`/api/reviews/${orderCode}`);
  return result.data;
};

export const setReviewStatus = async (orderCode, status) => {
  const result = await apiClient.post(`/api/reviews/${orderCode}`, {
    status: status,
  });
  return result.data;
};
